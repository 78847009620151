import "./sidebar.scss";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import collapseUp from "../../assets/images/up-arrow-svgrepo-com.svg";
import React, { useEffect, useState } from "react";
import AssetList from "../list-item/list-item";
import ListComponent from "../list.component/list.component";
import { sidebarActions } from "../../store/sidebar/sidebar.action";
import { IGetDataReq } from "../../store/sidebar/sidebar.interface";
import { setAllDataByListItem } from "../../store/sidebar/sidebar.slice";
import { useAppDispatch } from "../../store";
import { transformerActions } from "../../store/transformer/transformer.action";
import { customerActions } from "../../store/customer/customer.action";
import { commonUtils } from "../../utils/common.utils";
import { setTransformerDataRecords } from "../../store/transformer/transformer.slice";
import { setCustomerDataRecords } from "../../store/customer/customer.slice";
const SideNav = (props: any) => {
  const [value, setValue] = useState(" GSO/Rural");
  const [showDropdown, setShowDropdown] = useState(true);
  const [selectedValue, setSelectedValue] = useState("");
  const [masterArray, setMasterArray] = useState<Array<any>>(props.list);
  const [toggle, setToggle] = useState(false);
  // const [usedArray,setusedArray]=useState<Array<any>>(masterArray);
  const typeOrder = [
    "PowerTransformer",
    "EnergyConsumer",
    "PhotoVoltaicUnit",
    "BatteryUnit",
    "ACLineSegment",
    "LoadBreakSwitch",
  ];
  const dispatch = useAppDispatch();
  useEffect(() => {
    let subArray = [...props?.list];
    const sortedArray = subArray.sort(sortByTypeOrder);
    setMasterArray(sortedArray);
  }, [props.list]);

  const sortByTypeOrder = (a: any, b: any) => {
    const indexA = typeOrder.indexOf(a.type);
    const indexB = typeOrder.indexOf(b.type);

    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  };

  const onButtonClick = (data: any) => {
    props.onButtonClick(data);
    // setValue(data.type+": "+data.name);
  };

  const toggleButton = () => {
    setToggle(!toggle);
  };

  const onBuildClick = async (data: any) => {
    let sideBarReq: IGetDataReq = {
      rid: data.rid,
      name: data.name,
      type: data.type,
      time: commonUtils.getCurrentUTCTime(),
    };
    setValue(data.type + ": " + data.name);
    props.setSidebarValue(data.type + ": " + data.name);
    dispatch(
      setTransformerDataRecords({
        lines: [],
        connectivity: [],
        storageSummary: [],
        consumerSummary: [],
        pvSummary: [],
        consumers: [],
        acLineSegment: [],
        connectivityNodes: [],
        consumerData: [],
        pvData: [],
        storageData: [],
        transformerDataCheck: false,
      })
    );
    dispatch(
      setCustomerDataRecords({
        consumer: [],
        pvSummary: [],
        storageSummary: [],
        connectivityData: [],
        pvData: [],
        cunsumerDataCheck: false,
      })
    );
    if (data.name.endsWith("0.48Kv") || data.name.endsWith("0.12Kv")) {
      dispatch(transformerActions.getTransformerDataRecords(sideBarReq));
    }
    dispatch(customerActions.getCustomerLoadsDataRecords(sideBarReq));
    await sidebarActions.getAllDataByListItem(sideBarReq).then((data) => {
      dispatch(setAllDataByListItem(data));
    });
  };
  return (
    <>
      <div style={{ padding: "10px 0px" }}>
        <div className={`sidebar ${props.isSidebarOpen ? "" : "collapsed"}`}>
          <div className="sidebar-content">
            <div
              className="left-section-selection-wrapper"
              style={{
                backgroundColor: "#012137",
                marginTop: 5,
                // borderRadius: 5,
                // height: "81vh",
                // overflowY: "scroll",
                // position: "relative",
              }}
            >
              <ListComponent
                masterArray={masterArray}
                onButtonClick={onButtonClick}
                onBuildClick={onBuildClick}
                toggleButton={toggleButton}
              />
              {/* {masterArray?.map((dd: any) => {
            return (
              <AssetList
                onButtonClick={onButtonClick}
                dataList={dd}
              ></AssetList>
            );
          })} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SideNav;
