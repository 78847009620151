import { useDispatch } from "react-redux";
import { AppThunk, store, useAppSelector } from "../index";
import { IMapInfoPayload } from "./map-info.interface";
import { mapInfoService } from "./map-info.service";
import {
  setLinesMapData,
  setMapData,
  setSingleMapData,
  setTransformersMapData,
  setenergyConsumersMapData,
  setBayMapData,
  setsubstationsMapData,
  setswitchingDevices,
  setChartData,
} from "./map-info.slice";
import { IDataRecordReq } from "../customer/customer.interface";

const endpointActions: any = {
  VOLTAGE_REGULATORS: setLinesMapData,
  TRANSFORMERS: setTransformersMapData,
  ENERGY_CONSUMERS: setenergyConsumersMapData,
  BAYS: setBayMapData,
  SUBSTATIONS: setsubstationsMapData,
  SWITCHING_DEVICES: setswitchingDevices,
};

const getSingleMapData = async (IMapInfoPayload: IMapInfoPayload) => {
  const params: IMapInfoPayload = IMapInfoPayload;
  try {
    const response = await mapInfoService.getSingleMapInfo(params);
    if (response.status) {
      return response.data.data;
      //  store.dispatch(setSingleMapData(response.data.data))
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

const getAllMapData = () => {
  try {
    const response: any = mapInfoService.getAllMapInfo();
    return response;
  } catch (err) {}
};

const getCircuitData = (circuitDataReq: IDataRecordReq): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await mapInfoService.getCircuitData(circuitDataReq);

      if (response?.data?.success) {
        if (response.status === 200) {
          dispatch(setChartData(response?.data?.data));
        }
      } else if (!response.data.success) {
      }
    } catch (error) {}
  };
};
export const mapInfoActions = {
  getAllMapData,
  getSingleMapData,
  getCircuitData,
};
