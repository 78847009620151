import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { customerActions } from "../../store/customer/customer.action";
import "./customer-style.scss";
import ChartComponent from "../chart.component/chart";
import CustomerDataTable from "./customer-data-table";
import Alarm from "../alarm.component/alarm";
import analyticsIcon from "../../assets/images/analytical-dashboard.svg";
import GrafanaComponent from "../grafana.component/garafana.component";
import { commonUtils } from "../../utils/common.utils";
import { IDataRecordReq } from "../../store/customer/customer.interface";
import { Tooltip } from "@mui/material";
import { IAlarmsPayload } from "../alarm.list.component/alarm.interface";
import { alarmInfoActions } from "../../store/alarm-info/alarm-info.actions";
import { IAlarmData } from "../../store/alarm-info/alarm-info.interface";
import CustomerDataTableWithStickyHeader from "./customer-data-table-sticky";
import { seqrOpsActions } from "../../store/seqr-ops/seqr-ops.action";

const Customer = () => {
  const dispatch = useAppDispatch();
  const [showGrafana, setShowGrafana] = useState(false);
  const [hover, setHover] = useState(false);
  const customerData = useAppSelector(
    (state) => state.sidebarSlice.EnergyConsumer
  );

  const alarmData: any = useAppSelector(
    (state) => state.alarmInfo.customerAlarmInfo
  );

  const consumerLoads = useAppSelector((state) => state.customerSlice.consumer);

  const connectivityData = useAppSelector(
    (state) => state.customerSlice.connectivityData
  );
  const pVData = useAppSelector((state) => state.customerSlice.pvData);
  const storageSummary = useAppSelector(
    (state) => state.customerSlice.storageSummary
  );
  const pvSummary = useAppSelector((state) => state.customerSlice.pvSummary);

  const [alarms, setAlarms] = useState<IAlarmData[]>();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dataset, setDatasets] = useState<any>({
    kwDataset: [],
    kvarDataset: [],
  });
  const [pvDataset, setPvDatasets] = useState<any>({
    irradianceDataset: [],
    temperatureDataset: [],
  });
  const [connectivityDataset, setConnectivityDataset] = useState<any>({
    currentDataset: [],
    voltageDataset: [],
  });
  const [pvSummaryDataset, setPvSummaryDataset] = useState<any>({
    columns: ["PV System", "Connectivity", "kW", "kVar"],
    data: [],
  });
  const [storageSummaryDataset, setStorageSummaryDataset] = useState<any>({
    columns: ["Battery", "Connectivity", "kW", "kVar", "State"],
    data: [],
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    const _dataset = commonUtils.getCustomerChartProperties([]);
    setDatasets({
      kvarDataset: _dataset.kvarDataset,
      kwDataset: _dataset.kwDataset,
    });

    const _pvDataset = commonUtils.getPvChartProperties([]);
    setPvDatasets({
      irradianceDataset: _pvDataset.irradianceDataset,
      temperatureDataset: _pvDataset.temperatureDataset,
    });

    const _connectivityDataset = commonUtils.getConnectivityChartProperties([]);
    setConnectivityDataset({
      currentDataset: _connectivityDataset.currentDataset,
      voltageDataset: _connectivityDataset.voltageDataset,
    });

    setPvSummaryDataset({
      ...pvSummaryDataset,
      data: [],
    });
    setStorageSummaryDataset({
      ...storageSummaryDataset,
      data: [],
    });
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const labels: any = commonUtils.generateXAxisLabels();

  const getAlarms = () => {
    if (customerData?.rid) {
      const alarmRequest: IAlarmsPayload = {
        name: customerData?.name,
        pageNo: 0,
        pageSize: 5,
        rid: customerData?.rid,
        searchKey: "",
        sortKey: "",
        sortOrder: "",
        time: commonUtils.getCurrentUTCTime(),
        type: customerData?.type,
      };

      alarmInfoActions.getAlarms(alarmRequest).then((response: any) => {
        setAlarms(response?.data?.alarms);
      });
    }
  };
  useEffect(() => {
    getAlarms();
    const getDataRecords = async () => {
      if (customerData?.rid) {
        const customerDetailsReq: IDataRecordReq = {
          rid: customerData?.rid,
          type: customerData.type,
          name: customerData.name,
          time: commonUtils.getCurrentUTCTime(),
        };
        try {
          dispatch(
            customerActions.getAllCustomerLoadsDataRecords(customerDetailsReq)
          );
          
        } catch (error) {
          dispatch(seqrOpsActions.setLoader(false));
        }
      } else {
        dispatch(seqrOpsActions.setLoader(false));
      }
    };
    getDataRecords();

    const intervalId = setInterval(getDataRecords, 300000);

    return () => clearInterval(intervalId);
  }, [customerData]);

  useEffect(() => {
    if (consumerLoads?.length > 0) {
      const _dataset = commonUtils.getCustomerChartProperties(consumerLoads);
      setDatasets({
        kvarDataset: _dataset.kvarDataset,
        kwDataset: _dataset.kwDataset,
      });
    }
  }, [consumerLoads]);

  useEffect(() => {
    if (pVData?.length > 0) {
      const _dataset = commonUtils.getPvChartProperties(pVData);
      setPvDatasets({
        irradianceDataset: _dataset.irradianceDataset,
        temperatureDataset: _dataset.temperatureDataset,
      });
    }
  }, [pVData]);

  useEffect(() => {
    if (connectivityData?.length > 0) {
      const _dataset =
        commonUtils.getConnectivityChartProperties(connectivityData);
      setConnectivityDataset({
        currentDataset: _dataset.currentDataset,
        voltageDataset: _dataset.voltageDataset,
      });
    }
  }, [connectivityData]);

  useEffect(() => {
    if (pvSummary?.length > 0) {
      const flatList = pvSummary.flatMap(({ connectivityNode, pvUnits }: any) =>
        pvUnits?.map((pvUnit: any) => ({
          name: pvUnit.name || "-",
          time: pvUnit.time,
          Connectivity: connectivityNode || "-",
          kW: (pvUnit.kw || 0).toFixed(2),
          kVar: (pvUnit.kvar || 0).toFixed(2),
        }))
      );

      const uniqueMap = new Map();
      flatList.forEach((item: any) => {
        if (
          !uniqueMap.has(item.name) ||
          item.time > uniqueMap.get(item.name).time
        ) {
          uniqueMap.set(item.name, item);
        }
      });
      const uniqueFlatList = Array.from(uniqueMap.values());
      setPvSummaryDataset((prevState: any) => ({
        ...prevState,
        data: uniqueFlatList.filter(
          (value) => value !== null && value !== undefined
        ),
      }));
    }
  }, [pvSummary]);

  useEffect(() => {
    if (storageSummary?.length > 0) {
      const flatList = storageSummary
        .flatMap(({ connectivityNode, batteries }: any) =>
        {
          return {name: batteries[0]?.name || "-",
          Connectivity: connectivityNode || "-",
          kW: (batteries[0]?.kw || 0).toFixed(2),
          kVar: (batteries[0]?.kvar || 0).toFixed(2),
          State: batteries[0]?.state || "-",
        }
      }
          // batteries?.map((battery: any) => ())
        )
        .filter((value: any) => value !== null && value !== undefined);

      setStorageSummaryDataset((prevState: any) => ({
        ...prevState,
        data: flatList,
      }));
    }
  }, [storageSummary]);
  return (
    <>
      <div className="dtech-customer-section-main-wrapper mt-3 static-section">
        <div className="container-fluid">
          <div
            className="container-fluid"
            style={{
              overflow: "scroll",
              height: windowHeight - 240 + "px",
            }}
          >
            <div className="dtech-customer-header-section">
              <div>
                <span className="dtech-customer-heading">{"Consumer- "}</span>
                <span className="customer-name-value">
                  {customerData?.name || "-"}
                </span>
                <span></span>
                {showGrafana ? (
                  <span
                    onClick={() => setShowGrafana(!showGrafana)}
                    className="dashboard-close-btn"
                  >
                    x
                  </span>
                ) : (
                  <img
                    onClick={() => setShowGrafana(!showGrafana)}
                    className="grafana-toggle-btn"
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                    src={analyticsIcon}
                    alt="analytics"
                    style={{
                      transform: hover ? "scale(1.2)" : "scale(1)",
                      transition: "transform 0.5s ease",
                    }}
                  />
                )}
              </div>
              <div className="customer-header-inner-grid-container">
                <div
                  className="customer-header-inner-grid"
                  style={{ marginRight: 20 }}
                >
                  <span
                    className="customer-header-title"
                    style={{ fontSize: 12 }}
                  >
                    Bay
                  </span>
                  <span className="customer-header-title-value">
                    {customerData?.instance?.transformer?.replaceAll("tr(","").replaceAll(")","") || "-"}
                  </span>
                </div>
                <div
                  className="customer-header-inner-grid"
                  style={{ marginRight: 20 }}
                >
                  <span
                    className="customer-header-title"
                    style={{ fontSize: 12 }}
                  >
                    Substation
                  </span>
                  <span className="customer-header-title-value">
                    {customerData?.instance?.subStation || "-"}
                  </span>
                </div>
                <div
                  className="customer-header-inner-grid"
                  style={{ marginRight: 20 }}
                >
                  <span
                    className="customer-header-title"
                    style={{ fontSize: 12 }}
                  >
                    Voltage levels
                  </span>
                  <span className="customer-header-title-value">
                    {customerData?.instance?.voltageLevels?.join(", ") || "-"}
                  </span>
                </div>
                <div
                  className="customer-header-inner-grid"
                  style={{ marginRight: 20 }}
                >
                  <span
                    className="customer-header-title"
                    style={{ fontSize: 12 }}
                  >
                    Connectivity Node
                  </span>
                  <span className="customer-header-title-value">
                    {customerData?.instance?.connectivityNodes?.join(", ") ||
                      "-"}
                  </span>
                </div>

                <div
                  className="customer-header-inner-grid"
                  style={{ fontSize: 12 }}
                >
                  <span
                    className="customer-header-title"
                    style={{ fontSize: 12 }}
                  >
                    Line
                  </span>
                  <span className="customer-header-title-value">
                    {customerData?.instance?.line?.join(", ")?.replaceAll("l(","").replaceAll(")","") || "-"}
                  </span>
                </div>
              </div>
            </div>
            {showGrafana ? (
              <GrafanaComponent consumerName={customerData?.name} />
            ) : (
              <>
                <div className="row mt-3 col-sm-12 dtech-day-load-curve-data-section">
                  <div
                    className={`${
                      windowWidth < 1200
                        ? "col-sm-12 p-0 mb-3"
                        : "col-sm-6 dtech-day-load-curve-data-charts"
                    }`}
                  >
                    <div className="dtech-day-load-curve-data-div">
                      <ChartComponent
                        labels={labels}
                        datasets={dataset?.kwDataset}
                        title={"Day Load Curve (kW)"}
                        yAxisTitle={"kW Total"}
                        xAxisTitle={"Hour of the Day"}
                      />
                    </div>
                  </div>
                  <div
                    className={`${
                      windowWidth < 1200
                        ? "col-sm-12 p-0"
                        : "col-sm-6 dtech-day-load-curve-data-charts"
                    }`}
                  >
                    <div className="dtech-day-load-curve-data-div">
                      <ChartComponent
                        labels={labels}
                        datasets={dataset?.kvarDataset}
                        title={"Day Load Curve (kVar)"}
                        yAxisTitle={"kVar Total"}
                        xAxisTitle={"Hour of the Day"}
                      />
                    </div>
                  </div>
                </div>
                <div className="row col-sm-12">
                  <div
                    className={`${
                      windowWidth < 1200 ? "col-sm-12 p-0" : "col-sm-8"
                    }`}
                  >
                    <div className="row dtech-other-load-data-section">
                      <div className="dtech-other-load-data-charts">
                        <div className="dtech-day-load-curve-data-div">
                          <ChartComponent
                            labels={labels}
                            datasets={connectivityDataset?.currentDataset}
                            title={"Current (loading%)"}
                            yAxisTitle={"Current (loading%)"}
                            xAxisTitle={"Time (24 hours)"}
                            suggestedMax={1.5}
                            labelPosition={"bottom"}
                          />
                        </div>
                      </div>
                      <div className="dtech-other-load-data-charts">
                        <div className="dtech-day-load-curve-data-div">
                          <ChartComponent
                            labels={labels}
                            datasets={connectivityDataset?.voltageDataset}
                            title={"Current (loading%)"}
                            yAxisTitle={"Current (loading%)"}
                            xAxisTitle={"Time (24 hours)"}
                            suggestedMax={1.05}
                            labelPosition={"bottom"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row dtech-other-load-data-section">
                      <div className="dtech-other-load-data-charts">
                        <div className="dtech-day-load-curve-data-div">
                          <ChartComponent
                            labels={labels}
                            datasets={pvDataset.irradianceDataset}
                            labelPosition={"bottom"}
                            title={"Irradiance"}
                            yAxisTitle={"Irradiance(p.u)"}
                            xAxisTitle={"Time (24 hours)"}
                          />
                        </div>
                      </div>
                      <div className="dtech-other-load-data-charts">
                        <div className="dtech-day-load-curve-data-div">
                          <ChartComponent
                            labels={labels}
                            datasets={pvDataset.temperatureDataset}
                            labelPosition={"bottom"}
                            title={"Temperature"}
                            yAxisTitle={"Temperature(degC)"}
                            xAxisTitle={"Time (24 hours)"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "space-around",
                    }}
                    className={`${
                      windowWidth < 1200
                        ? "col-sm-12 p-0 mt-3"
                        : "col-sm-4 mt-2"
                    }`}
                  >
                    <div className="row dtech-other-load-data-table-section">
                      <CustomerDataTableWithStickyHeader
                        data={pvSummaryDataset.data}
                        columnsToShow={pvSummaryDataset.columns}
                        showConnectivity={true}
                      />
                    </div>
                    <div className="row dtech-other-load-data-table-section">
                      <CustomerDataTableWithStickyHeader
                        data={storageSummaryDataset.data}
                        columnsToShow={storageSummaryDataset.columns}
                        showConnectivity={true}
                      />
                    </div>
                    <div className="row dtech-alarm-table-container">
                      <Alarm alarmData={alarms} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Customer;
