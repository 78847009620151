import * as React from "react";
import { memo, useEffect, useRef, useState } from "react";
import Map from "../map.component/map";
import Navbar from "../navbar.component/navbar";
import SideNav from "../sidebar.component/sidebar";
import "./home.scss";
import Alarm from "../alarm.component/alarm";
import { useDispatch } from "react-redux";
import {
  setBayMapData,
  setLinesMapData,
  setMapData,
  setSingleMapData,
  setTransformersMapData,
  setenergyConsumersMapData,
  setsubstationsMapData,
  setswitchingDevices,
  setSidebarData,
  setCheckBoxItems,
  setCheckedItem,
  setCheckedValues,
} from "../../store/map-info/map-info.slice";
import QueryBuilder from "../query.builder/query.builder";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { TabList, TabPanel } from "@mui/lab";
import { Routes, Route, Outlet, useNavigate } from "react-router-dom";
import Customer from "../customer.component/customer";
import SubStation from "../substation.component/substation";
import Transformer from "../Transformer.component/transformer";
import { mapInfoActions } from "../../store/map-info/map-info.action";
import { IMapInfoPayload } from "../../store/map-info/map-info.interface";
import L from "leaflet";
import { store, useAppDispatch, useAppSelector } from "../../store";
import { assetInfoActions } from "../../store/asset-list/asset-list.action";
import { setAssetInfo } from "../../store/asset-list/asset-list.slice";
import FilterIcon from "../../assets/images/filter_icon.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Form } from "react-bootstrap";
import Chart from "../chart.component/chart";
import ChartComponent from "../chart.component/chart";
import moment from "moment";
import { mapInfoService } from "../../store/map-info/map-info.service";
import JSZip from "jszip";
import { alarmInfoActions } from "../../store/alarm-info/alarm-info.actions";
import { setAlarmInfo } from "../../store/alarm-info/alarm-info.slice";
import { customerActions } from "../../store/customer/customer.action";
import { transformerService } from "../../store/transformer/transformer.services";
import AlarmListComponent from "../alarm.list.component/alarm.list.component";
import { IGetDataReq } from "../../store/sidebar/sidebar.interface";
import { sidebarActions } from "../../store/sidebar/sidebar.action";
import { setAllDataByListItem } from "../../store/sidebar/sidebar.slice";
import AlarmListComponentNew from "../alarm.list.component/alarm-list-component-new";
import { useLocation } from "react-router-dom";
import { substationActions } from "../../store/substation/substation.action";
import { transformerActions } from "../../store/transformer/transformer.action";
import { commonUtils } from "../../utils/common.utils";
import { IGetDataRecordsReq } from "../../store/transformer/transformer.interface";
import { setTransformerDataRecords } from "../../store/transformer/transformer.slice";
import { setCustomerDataRecords } from "../../store/customer/customer.slice";

function Home() {
  const [value, setValue] = React.useState("1");
  const [show, setShow] = useState(false);
  const [mapKey, setMapKey] = useState(0);
  const [listData, setListData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const [checkedValue, setCheckedValue] = useState("");

  const getMapInfo = useAppSelector((state) => state.mapInfoSlice.allMapData);
  const getAssetInfo = useAppSelector((state) => state.AssetInfo.assetInfo);
  const customerData = useAppSelector(
    (state) => state.sidebarSlice.EnergyConsumer
  );
  const transformerData = useAppSelector((state) => state.sidebarSlice.Bay);
  const theme = useAppSelector((state) => state.seqrOps.theme);
  const acLineSegment = useAppSelector(
    (state) => state.transformerSlice.acLineSegment
  );
  const connectivityNodes = useAppSelector(
    (state) => state.transformerSlice.connectivityNodes
  );
  const pvSummary = useAppSelector((state) => state.transformerSlice.pvData);
  const transformerDataCheck = useAppSelector(
    (state) => state.transformerSlice.transformerDataCheck
  );
  const cunsumerDataCheck = useAppSelector(
    (state) => state.customerSlice.cunsumerDataCheck
  );
  const consumersData = useAppSelector(
    (state) => state.transformerSlice.consumerData
  );
  const storageData = useAppSelector(
    (state) => state.transformerSlice.storageData
  );
  const connectivityDataCus = useAppSelector(
    (state) => state.customerSlice.connectivityData
  );
  const pVDataCus = useAppSelector((state) => state.customerSlice.pvData);
  const storageSummaryCus = useAppSelector(
    (state) => state.customerSlice.storageSummary
  );
  const pvSummaryCus = useAppSelector((state) => state.customerSlice.pvSummary);
  const consumerLoadsCus = useAppSelector(
    (state) => state.customerSlice.consumer
  );
  const dispatch = useAppDispatch();

  const MemoizedTabList = React.memo(TabList);

  let ApiTypeArray: Array<any> = [
    {
      label: "Voltage Regulators",
      value: "VOLTAGE_REGULATORS",
      color: "#7eabdf",
    },
    { label: "Transformers", value: "TRANSFORMERS", color: "#4A1873" },
    { label: "Energy Consumers", value: "ENERGY_CONSUMERS", color: "#000000" },
    {
      label: "Lines",
      value: "BAYS",
      color: ["#00FF00", "#FF0000", "#0000FF", "#000000"],
    },
    { label: "Substations", value: "SUBSTATIONS", color: "#173A0B" },
    {
      label: "Switching Devices",
      value: "SWITCHING_DEVICES",
      color: "#F1E947",
    },
  ];

  const [checkedItems, setCheckedItems] = useState(
    ApiTypeArray.reduce((acc, item) => {
      acc[item.value] = item.value === "SUBSTATIONS" ? false : true;
      return acc;
    }, {} as Record<string, boolean>)
  );

  const [checkedAndUncheckedItems, setCheckedAndUncheckedItems] = useState<
    Array<{ value: string; checked: boolean; color: string }>
  >(
    ApiTypeArray.map((item) => ({
      value: item.value,
      checked: item.value !== "SUBSTATIONS",
      color: item.color,
    }))
  );

  console.log("transformerDataCheck", transformerDataCheck, transformerData);

  useEffect(() => {
    setMapKey((prevKey) => prevKey + 1);
    switch (location?.pathname) {
      case "/map":
        setValue("1");
        break;
      case "/substation":
        setValue("2");
        break;
      case "/transformer":
        setValue("3");
        break;
      case "/consumer":
        setValue("4");
        break;
      case "/alarms":
        setValue("5");
        break;
      default:
        setValue("1");
        break;
    }
  }, [location]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setMapKey((prevKey) => prevKey + 1);
    setValue(newValue);

    switch (newValue) {
      case "1":
        navigate("/");
        break;
      case "2":
        navigate("/substation");
        break;
      case "3":
        navigate("/transformer");
        break;
      case "4":
        navigate("/consumer");

        break;
      case "5":
        navigate("/alarms");

        break;
      default:
        break;
    }
  };
  let i: any = 0;
  const [windowWidth, setWindowWidth] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    dispatch(setCheckBoxItems(checkedAndUncheckedItems));

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      (value == "3" && transformerData == null) ||
      (value == "3" && !transformerDataCheck)
    ) {
      setValue("1");
      navigate("/");
    } else if (
      (value == "4" && !cunsumerDataCheck) ||
      (value == "4" && customerData == null)
    ) {
      setValue("1");
      navigate("/");
    }
  }, [transformerData, transformerDataCheck, cunsumerDataCheck, customerData]);

  useEffect(() => {
    dispatch(setCheckedValues(checkedItems[checkedValue]));
  }, [dispatch, checkedItems]);
  useEffect(() => {
    assetInfoActions
      .getAssetInfo([
        {
          type: "Substation",
          operator: "STARTS_WITH",
          value: "",
          logicalOperator: "AND",
          rid: "",
        },
      ])
      .then(async (data) => {
        dispatch(setAssetInfo(data));
        let value = data[0].assets[0];
        let sideBarReq: IGetDataReq = {
          rid: value.rid,
          type: value.type,
          name: value.name,
          time: commonUtils.getCurrentUTCTime(),
        };

        dispatch(transformerActions.getTransformerDataRecords(sideBarReq));
        dispatch(customerActions.getCustomerLoadsDataRecords(sideBarReq));
        await sidebarActions.getAllDataByListItem(sideBarReq).then((data) => {
          dispatch(setAllDataByListItem(data));
        });
      });
    // alarmInfoActions.setAlarmInfo().then((data: any) => {
    //   dispatch(setAlarmInfo(data));
    // });

    // if(i==0){

    mapInfoService.getAllMapInfo().then(async (resp: any) => {
      if (resp) {
        const arrayBuffer = resp.data;
        const zip: any = await JSZip.loadAsync(arrayBuffer);
        const jsonData: any = await zip.file("data.json").async("string");
        const mapData = JSON.parse(jsonData);
        dispatch(setMapData(mapData));
      }
    });
    // }
    i = 1;
  }, []);

  // mapInfoService.getAllMapInfo().then(async (resp: any) => {

  const onFilterClick = async () => {
    setShow(true);
  };
  const onButtonClick = async (value: IMapInfoPayload) => {
    setListData(value);
    dispatch(setSidebarData(value));
    dispatch(
      setTransformerDataRecords({
        lines: [],
        connectivity: [],
        storageSummary: [],
        consumerSummary: [],
        pvSummary: [],
        consumers: [],
        acLineSegment: [],
        connectivityNodes: [],
        consumerData: [],
        pvData: [],
        storageData: [],
        transformerDataCheck: false,
      })
    );
    dispatch(
      setCustomerDataRecords({
        consumer: [],
        pvSummary: [],
        storageSummary: [],
        connectivityData: [],
        pvData: [],
        cunsumerDataCheck: false,
      })
    );
    let sideBarReq: IGetDataReq = {
      rid: value.rid,
      name: value.name,
      type: value.type,
      time: commonUtils.getCurrentUTCTime(),
    };
    // {"rid":"#598:97","name":"Bay_tr(r:rdt903-rdt903lv)_0.12Kv","type":"Bay","time":"2024-02-25 12:15:47"}

    // const transformerDetailsReq: IGetDataRecordsReq = {
    //   rid: "#598:97",
    //   type: "Bay",
    //   name: "Bay_tr(r:rdt903-rdt903lv)_0.12Kv",
    //   time: "2024-02-25 12:30:00",
    // };
    if (value.name.endsWith("0.48Kv") || value.name.endsWith("0.12Kv")) {
      dispatch(transformerActions.getTransformerDataRecords(sideBarReq));
    }
    dispatch(customerActions.getCustomerLoadsDataRecords(sideBarReq));
    // dispatch(substationActions.getSubstationDataRecords(sideBarReq));
    // dispatch(transformerActions.getTransformerDataRecords(sideBarReq))
    // dispatch(customerActions.getCustomerDataRecords(sideBarReq)
    // )
    await sidebarActions.getAllDataByListItem(sideBarReq).then((data) => {
      dispatch(setAllDataByListItem(data));
    });
    // await customerActions.getCustomerData(value);
    // await substationActions.getTransformerData(value);
    // await transformerService.getTransformerData(value);
  };

  const generateMappedArray = (prevItems: any, item: any, checked: any) =>
    prevItems.map((prevItem: { value: any }) =>
      prevItem.value === item ? { ...prevItem, checked } : prevItem
    );

  const handleCheckboxChange = (item: string, checked: boolean) => {
    setCheckedAndUncheckedItems((prevItems) =>
      prevItems.map((prevItem) =>
        prevItem.value === item ? { ...prevItem, checked } : prevItem
      )
    );
    const updatedArray = generateMappedArray(
      checkedAndUncheckedItems,
      item,
      checked
    );
    dispatch(setCheckBoxItems(updatedArray));
    dispatch(setCheckedItem(item));
    setCheckedValue(item);
    setCheckedItems((prev: any) => ({
      ...prev,
      [item]: !prev[item],
    }));
  };

  const handleClose = () => {
    setShow(false);
  };

  const MemoizedMap = React.memo(Map);

  const renderMapComponent = () => <MemoizedMap key={mapKey} />;

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [sidebarValue, setSidebarValue] = useState("GSO/Rural");

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const getBayColor = (item: any, index: number) => {
    if (item?.value === "BAYS") {
      if (Array.isArray(item?.color) && item.color.length > 0) {
        const colorIndex = index % item.colors.length;
        const selectedColors = item.color.slice(colorIndex, colorIndex + 3);
        return selectedColors;
      } else {
      }
    } else {
    }

    return item.color;
  };

  // ... Your existing JSX code

  return (
    <div className="dtech-main-app">
      <div className="w-100">
        <QueryBuilder
          toggleSidebar={toggleSidebar}
          sidebarValue={sidebarValue}
        />
      </div>
      <div
        className="w-100"
        style={{
          flexDirection: "row",
          display: "flex",
          height: "100%",
          // height: "calc(100% - 70px)",
        }}
      >
        <SideNav
          toggleSidebar={toggleSidebar}
          isSidebarOpen={isSidebarOpen}
          onButtonClick={onButtonClick}
          setSidebarValue={setSidebarValue}
          list={getAssetInfo}
        />
        <div
          style={{ width: windowWidth - (isSidebarOpen ? 400 : 0) + "px" }}
          className="main-content"
        >
          <div
            className="top-bar"
            style={
              {
                // border: "1px solid #494950"
              }
            }
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <TabContext value={value}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <MemoizedTabList onChange={handleChange}>
                    <Tab
                      style={{ color: "white", textTransform: "none" }}
                      label="Map View"
                      value="1"
                    />
                    <Tab
                      style={{ color: "white", textTransform: "none" }}
                      label="Substation"
                      value="2"
                    />
                    <Tab
                      hidden={
                        transformerData == null || !transformerDataCheck
                          ? true
                          : false
                      }
                      style={{ color: "white", textTransform: "none" }}
                      label="Bay"
                      value="3"
                    />
                    <Tab
                      hidden={
                        customerData == null || !cunsumerDataCheck
                          ? true
                          : false
                      }
                      style={{ color: "white", textTransform: "none" }}
                      label="Consumer"
                      value="4"
                    />
                    <Tab
                      style={{ color: "white", textTransform: "none" }}
                      label="Alarms"
                      value="5"
                    />
                  </MemoizedTabList>

                  <Offcanvas
                    show={show}
                    id={theme ? "dark" : "light"}
                    className={"offcanvas"}
                    style={{ width: "300px" }}
                    placement="end"
                    onHide={handleClose}
                  >
                    <div className="side-canvas">
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title></Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        {checkedAndUncheckedItems.map((item, index) => (
                          <Form style={{ marginBottom: "20px" }}>
                            <div
                              key={index}
                              className="indicator"
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Form.Check
                                key={index}
                                type="checkbox"
                                label={
                                  ApiTypeArray.find(
                                    (apiItem) => apiItem.value === item.value
                                  )?.label || ""
                                }
                                checked={item.checked}
                                onChange={() =>
                                  handleCheckboxChange(
                                    item.value,
                                    !item.checked
                                  )
                                }
                              />
                            </div>

                            {item.value !== "BAYS" && (
                              <div
                                style={{
                                  backgroundColor: item.color,
                                  width: "16px",
                                  height: "16px",
                                  display: "inline-block",
                                  marginRight: "5px",
                                  marginLeft: "24px",
                                  borderRadius: "0",
                                }}
                              ></div>
                            )}
                            {item.value === "BAYS" &&
                              Array.isArray(item.color) &&
                              item.color.length > 0 &&
                              item.color.map((color, colorIndex) => (
                                <div
                                  key={colorIndex}
                                  style={{
                                    backgroundColor: color,
                                    width: "16px",
                                    height: "16px",
                                    display: "inline-block",
                                    marginRight: "2px",
                                    marginLeft: "24px",

                                    borderRadius: "0",
                                  }}
                                ></div>
                              ))}
                          </Form>
                        ))}
                      </Offcanvas.Body>
                    </div>
                  </Offcanvas>
                </Box>
                <div style={{ justifyContent: "flex-end" }}>
                  <img
                    onClick={onFilterClick}
                    height={40}
                    width={40}
                    src={FilterIcon}
                  ></img>
                </div>
              </TabContext>
            </div>
            <span
              className="seqrops-query-section-text"
              style={{
                fontSize: "0.875rem",
                padding: "5px 15px",
                display: "flex",
              }}
            >
              <i>{sidebarValue?.replaceAll("tr(","").replaceAll(")","")}</i>
            </span>
            <Map isMapTab={value === "1" ? true : false} />
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
export default memo(Home);
