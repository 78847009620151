//API URl

export const BASE_URL = `${process.env.REACT_APP_MODEL_API_URL}`;
export const DEVICE_BASE_URL = `${process.env.REACT_APP_DEVICE_API_URL}`;
export const FILE_BASE_URL = `${process.env.REACT_APP_FILE_API_URL}`;
export const KEYCLOAK_BASE_URL = `${process.env.REACT_APP_KEYCLOAK_API_URL}`;
export const AUTH_BASE_URL = `${process.env.REACT_APP_AUTH_API_URL}`;
export const ASSET_SUITE_LOGIN_URL = `${process.env.REACT_APP_APP_SUITE_URL}`;
export const CONTRACT_URL = `${process.env.REACT_APP_CONTRACT_URL}`;
export const GRID_MONOTORING_URL = `${process.env.REACT_APP_GRID_MONOTORING_API_URL}`;
export const SIMULATION_API_URL = `${process.env.REACT_APP_SIMULATION_API_URL}`;
export const DTECH_BASE_URL = "https://dtech-api.seqrops.in/";

export const THEME_CHANGE_URL = {
  // THEME_CHANGE :AUTH_BASE_URL+"api/v1/attributes/set-theme"
  THEME_CHANGE:
    "https://dtech-api.seqrops.in/oauth/api/v1/attributes/set-theme",
};

export const ASSET_URLS = {
  APP_SUITE_URL: `${process.env.REACT_APP_APP_SUITE_URL}/dashboard`,
  ASSET_MANAGEMENT_URL: `${process.env.REACT_APP_ASSETS_URL}`,
  ASSET_MODELS_URL: `${process.env.REACT_APP_MODELS_URL}`,
  DEVICE_MANAGEMENT_URL: `${process.env.REACT_APP_DEVICE_MANAGEMENT_URL}/#/devices`,
  FIELD_SERVICE_MANAGEMENT_URL: `${process.env.REACT_APP_FSM_URL}`,
  OPERATIONAL_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
  MANAGEMENT_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
  CONTRACT_URL: `${process.env.REACT_APP_CONTRACT_URL}`,
  MONITORING_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
  GRID_MONOTORING_APP: `${process.env.REACT_APP_GRID_MONOTORING_APP}`,
  DTECH_URL: `${process.env.REACT_APP_DTECH_URL}`,
  ANALYTICS_DASHBOARD: `${process.env.REACT_APP_ANALYTICS_DASHBOARD}`,
  CUSTOMER_LOADS_DASHBOARD: `${process.env.REACT_APP_CUSTOMER_LOADS_URL}`,
};

export const SIMULATION_URLS = {
  GET_ALL_ALARMS: SIMULATION_API_URL + "/get-all-alarms",
};

export const ASSET_AUTH_API = {
  GET_LOGIN_TOKEN: AUTH_BASE_URL + "api/v1/auth/token",
  VERIFY_LOGIN_TOKEN: AUTH_BASE_URL + "api/v1/auth/token/introspect",
  LOGOUT_TOKEN: AUTH_BASE_URL + "api/v1/auth/logout",
};

export const GRID_MONITORING_API = {
  GET_ALARMS: GRID_MONOTORING_URL + "api/v1/data/alarms",
  GET_CIRCUIT_RECORDS: GRID_MONOTORING_URL + "api/v1/data/get-circuit-data",
  GET_MODEL_DETAILS: GRID_MONOTORING_URL + "api/v1/data/get-model-details",
  GET_CUSTOMER_DETAILS:
    GRID_MONOTORING_URL + "api/v1/data/get-customer-details",
  GET_CUSTOMER_LOADS_DATA_RECORDS:
    GRID_MONOTORING_URL + "api/v1/data/get-customer-loads-data",
  GET_CUSTOMER_PV_DATA_RECORDS:
    GRID_MONOTORING_URL + "api/v1/data/get-customer-pv-data",
  GET_CUSTOMER_BUSES_DATA_RECORDS:
    GRID_MONOTORING_URL + "api/v1/data/get-customer-buses-data",
  GET_CUSTOMER_PV_SUMMARY_DATA_RECORDS:
    GRID_MONOTORING_URL + "api/v1/data/get-customer-pv-summary-data",
  GET_CUSTOMER_STORAGE_SUMMARY_DATA_RECORDS:
    GRID_MONOTORING_URL + "api/v1/data/get-customer-storage-summary-data",
  GET_TRANSFORMER_DATA_ENERGY_CONSUMER_RECORDS:
    GRID_MONOTORING_URL + "api/v1/data/get-transformer-energy-consumer-data",
  GET_TRANSFORMER_BATTERY_UNIT_DATA_RECORDS:
    GRID_MONOTORING_URL + "api/v1/data/get-transformer-battery-unit-data",
  GET_TRANSFORMER_PV_UNIT_DATA_RECORDS:
    GRID_MONOTORING_URL + "api/v1/data/get-transformer-pv-unit-data",
  GET_TRANSFORMER_DATA_BUSES_RECORDS:
    GRID_MONOTORING_URL + "api/v1/data/get-transformer-buses-data",
  GET_TRANSFORMER_DATA_LINES_RECORDS:
    GRID_MONOTORING_URL + "api/v1/data/get-transformer-lines-data",
  GET_TRANSFORMER_DATAS:
    GRID_MONOTORING_URL + "api/v1/data/get-transformer-details",
  GET_SUBSTATION_DATA_RECORDS:
    GRID_MONOTORING_URL + "api/v1/data/get-substation-data",
  GET_SUBSTATION_CIRCUIT_DATA_RECORDS:
    GRID_MONOTORING_URL + "api/v1/data/get-substation-circuit-data",
  GET_ALL_DATA_RECORDS: GRID_MONOTORING_URL + "api/v1/data/get-all-datas",
};
export const DTECH_ASSEST_URL = {
  GET_MAP_INFO: DTECH_BASE_URL + "monitoring/api/v1/qgis/getGeoJson",
  GET_ALL_MAP_INFO: DTECH_BASE_URL + "monitoring/api/v1/qgis/getAll/",
  GET_ALL_ASSET: DTECH_BASE_URL + "monitoring/api/v1/instance/getInstanceList",
};

export const COOKIE_DETAILS = {
  COOKIE_NAME: `${process.env.REACT_APP_COOKIE_PREFIX}_user_session_id`,
  COOKIE_DOMAIN: `${process.env.REACT_APP_COOKIE_DOMAIN}`,
};

export const IFRAME_ID = {
  SUITE_IFRAME_ID: "ifr_suite",
  MODELS_IFRAME_ID: "ifr_models",
  ASSETS_IFRAME_ID: "ifr_assets",
  DEVICE_MANAGEMENET_IFRAME_ID: "ifr_device",
  FSM_IFRAME_ID: "ifr_fsm",
  DASHBOARD_IFRAME_ID: "ifr_dashboard",
};

export const MESSAGES = {
  LOGGED_OUT: "Logged out Successfully",
  SOMETHING_WENT_WRONG: "Something went wrong",
  USER_UNAUTHORIZED: "User unauthorized",
  USERNAME_REQUIRED: "Please enter username",
  PASSWORD_REQUIRED: "Please enter password",
  INVALID_LOGIN_CREDENTIALS: "Invalid login credentials",
};

export const SESSION_TYPE = {
  USER_LOGIN_TYPE: "1",
  USER_LOGOUT_TYPE: "0",
  DOMAIN_PASS_TYPE: "2",
};

export const RESERVED_PROPS = {
  RESERVED_PROPERTY_DELETED: "deleted",
};

export const KEYCLOAK_GRANT_TYPES = {
  PASSWORD: "password",
  CLIENT_CREDENTIALS: "client_credentials",
  REFRESH_TOKENS: "refresh_token",
};

export const KEYCLOAK_CLIENTS = {
  ASSET_SUITE: "asset-suite",
  ASSET_MANAGEMENT: "asset-management",
  ASSET_MODELS: "asset-models",
  DEVICE_MANAGEMENT: "device-management",
  FIELD_SERVICE_MANAGEMENT: "field-service-management",
  OPERATIONAL_DASHBOARD: "operational-dashboard",
  MANAGEMENT_DASHBOARD: "management-dashboard",
  MONITORING_DASHBOARD: "monitoring-dashboard",
  ASSET_CONTRACT: "asset-contract",
  GRID_MONITORING_APP: "grid-monitoring-app",
  ANALYTICS_DASHBOARD: "analytics-dashboard",
};

export const CLEAR_CLIENT_SCOPES = "clear-scopes";

export const KEYCLOAK_CLIENT_SECRETS = {
  ASSET_SUITE: `${process.env.REACT_APP_SUITE_CLIENT_SECRET}`,
};

export const DIRECT_DASHBOARD_ACCESS_USERS = ["shery"];

export const SESSION_KEY = {
  NAVIGATION_STATE: "vdfwfznVgu",
  LOCAL_STORAGE_KEY: "ut",
  REDIRECT_LOCATION_KEY: "rl",
  CLEAR_ALL_TOKEN_DATA_KEY: "cl",
  DOMAIN_KEY: "dmn",
  SUITE_KEY: "su1te",
  MODELS_KEY: "mod3ls",
  ASSETS_KEY: "ass3ts",
  DEVICE_MANAGEMENET_KEY: "d3vice",
  FSM_KEY: "f2m",
  OPERATIONAL_DASHBOARD_KEY: "0pd",
  MANAGEMENT_DASHBOARD_KEY: "Wgmt",
};

export const ERROR_CODES = {
  SCHEMA_DB_NOT_FOUND: "SCHEMA_DB_NOT_FOUND",
};

export const DASHBOARD_TYPE = {
  REDIRECT: "redirectFrom",
};
export const ASSET_REDIRECT_SCOPES = {
  ASSET_SUITE: "asset_suite",
  ASSET_MANAGEMENT: "asset_management",
  ASSET_MODELS: "asset_models",
  DEVICE_MANAGEMENT: "device_management",
  FIELD_SERVICE_MANAGEMENT: "field_service_management",
  OPERATIONAL_DASHBOARD: "operational_dashboard",
  MANAGEMENT_DASHBOARD: "management_dashboard",
  ASSET_CONTRACT: "asset_contract",
  MONITORING_DASHBOARD: "monitoring_dashboard",
  ANALYTICS_DASHBOARD: "analytics_dashboard",
  GRID_MONITORING_APP: "grid_monitoring_app",
};
export const ASSET_SCOPES = [
  "asset-management",
  "asset-models",
  "device-management",
  "asset-suite",
  "field-service-management",
  "operational-dashboard",
  "management-dashboard",
  "asset-contract",
  "monitoring-dashboard",
  "grid-monitoring-app",
  "analytics-dashboard",
];
export const ASSET_MANAGEMENT_SCOPE = "asset-management";
export const ASSET_SUITE_SCOPE = "asset-suite";
export const ASSET_MODELS_SCOPE = "asset-models";
export const DEVICE_MANAGEMENET_SCOPE = "device-management";
export const FIELD_SERVICE_MANAGEMENT = "field-service-management";
export const MANAGEMENT_DASHBOARD = "operational-dashboard";
export const OPERATIONAL_DASHBOARD = "management-dashboard";

export const UNAUTHORIZED_LOGIN = "Unauthorized";
