import React, { useEffect, useState } from "react";
import "./substation-style.scss";
import { useAppDispatch, useAppSelector } from "../../store";
import { substationActions } from "../../store/substation/substation.action";
import {
  IGetDataRecordsReq,
  IGetModelDetailsReq,
  ISubsstationDataRecords,
} from "../../store/substation/substation.interface";
import { Tooltip } from "@mui/material";
import ChartComponent from "../chart.component/chart";
import Alarm from "../alarm.component/alarm";
import { commonUtils } from "../../utils/common.utils";
import { ISubsstationDatas } from "../../store/sidebar/sidebar.interface";
import { IDataRecordReq } from "../../store/customer/customer.interface";
import { IAlarmsPayload } from "../alarm.list.component/alarm.interface";
import { alarmInfoActions } from "../../store/alarm-info/alarm-info.actions";
import { IAlarmData } from "../../store/alarm-info/alarm-info.interface";
import BarChartComponent from "../chart.component/barChart";
import ScatterChartComponent from "../chart.component/scatterChart";
import { seqrOpsActions } from "../../store/seqr-ops/seqr-ops.action";

const SubStation = () => {
  const dispatch = useAppDispatch();
  const [alarms, setAlarms] = useState<IAlarmData[]>();
  const substationData = useAppSelector(
    (state) => state.sidebarSlice.Substation
  );
  const alarmData: any = useAppSelector(
    (state) => state.alarmInfo.substationAlarmInfo
  );
  const substationDataRecords: any = useAppSelector(
    (state) => state.substationSlice
  );

  const [substation, setSubstation] = useState<ISubsstationDatas>({
    name: "",
    instance: [],
    mRID: "",
    schema: [],
    rid: "",
    type: "Substation",
  });
  const [dataset, setDataSet] = useState<any[]>([]);
  const [datasetpercentile, setDatasetPercentile] = useState<any[]>();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const labels: any = commonUtils.generateXAxisLabels();
  useEffect(() => {
    getAlarms();
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const formatPropertyName = (name: string) => {
    const words = name.split(/(?=[A-Z])|_/);
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  };
  const dummyProperty: any = {
    id: 1457426,
    substation: "rhs0",
    peakRealLossesKw: 92.6279741624376,
    peakRealLossesPercentage: 5.674487692764462,
    peakCircuitPower: 1632.3583586329303,
    peakRealCustomerpower: 3029.979034700575,
    peakRealCommercialpower: 590.256176338955,
    peakRealResidentialpower: 2439.7228583616215,
    peakLoadHourOfDay: 16.0,
    peakLoadMinuteOfHour: 0.0,
    time: "2022-02-20T19:15:00.000+00:00",
  };

  const totalPeakDmmyData = {
    id: 1,
    total_peakCircuitPower: 78978.68516789995,
    total_peakRealCustomerPower_kw: 94475.86984190615,
    total_peakRealResidentialPower_kw: 63700.88075328499,
    total_peakRealLosseskw: 4061.930011623981,
    total_peakRealLossesPercentage: 4.770524594576951,
    total_peakRealCommercialPower_kw: 30774.98908862114,
    time: "2022-02-20T19:15:00.000+00:00",
  };

  const getAlarms = () => {
    const alarmRequest: IAlarmsPayload = {
      totalRecords: 0,
      totalFilteredRecords: 0,
      pageNo: 1,
      pageSize: 5,
      rid: substationData?.rid,
      searchKey: "",
      sortKey: "",
      sortOrder: "",
      time: commonUtils.getCurrentUTCTime(),
      type: substationData?.type,
    };

    alarmInfoActions.getAlarms(alarmRequest).then((response: any) => {
      setAlarms(response?.data?.alarms);
    });
  };

  useEffect(() => {
    getAlarms();
    const getDataRecords = async () => {
      if (substationData?.rid) {
        const customerDetailsReq: IDataRecordReq = {
          rid: substationData.rid,
          type: substationData.type,
          name: substationData.name,
          time: commonUtils.getCurrentUTCTime(),
        };

        try {
          dispatch(
            substationActions.getSubstationDataAllRecords(customerDetailsReq));
        } catch (error) {
          console.error("Error fetching data records:", error);
          dispatch(seqrOpsActions.setLoader(false));
        }
      }
    };
    getDataRecords();

    const intervalId = setInterval(getDataRecords, 300000);

    return () => clearInterval(intervalId);
  }, [substationData]);

  useEffect(() => {
    if (substationDataRecords?.substation?.length > 0) {
      const _dataset = commonUtils.getSubstationLineChartProperties(
        substationDataRecords.substation
      );
      setDataSet(_dataset.currentDataset);
    }

    if (substationDataRecords?.percentile?.length > 0) {
      
      const _dataset = commonUtils.getSubstationVoltageChartProperties(
        substationDataRecords?.percentile
      );
      setDatasetPercentile(_dataset.currentDataset);
    } else {
      setDatasetPercentile([]);
    }
  }, [substationDataRecords]);

  const getLargestValueofCircuitPower = (key: string) => {
    const maxValue = Math.max(
      ...substationDataRecords.circuitPower.map((o: any) => o[key])
    );
    if (maxValue) {
      return maxValue;
    } else {
      return 0.0;
    }
  };
  const getLargestValueofSubstation = (key: string) => {
    const maxValue = Math.max(
      ...substationDataRecords.substation.map((o: any) => o[key])
    );
    if (maxValue) {
      return maxValue;
    } else {
      return 0.0;
    }
  };

  const propertyItems = (
    <>
      <div className="dtech-subsstation-model-peak-time-property-list-item">
        <span className="dtech-subsstation-model-property-list-item-value">
          {substationDataRecords?.circuitPower?.length > 0
            ? substationDataRecords?.circuitPower[0][
                "totalreallosseskw"
              ]?.toFixed(2) + " kW"
            : 0.0}
        </span>
        <Tooltip title="Real losses">
          <span className="dtech-subsstation-model-property-list-item-name">
            Real losses
          </span>
        </Tooltip>
      </div>
      <div className="dtech-subsstation-model-peak-time-property-list-item">
        <span className="dtech-subsstation-model-property-list-item-value">
          {substationDataRecords?.circuitPower?.length > 0
            ? substationDataRecords?.circuitPower[0][
                "totalcircuitpower"
              ]?.toFixed(2) + " kW"
            : 0.0}
        </span>
        <Tooltip title="Circuit power">
          <span className="dtech-subsstation-model-property-list-item-name">
            Circuit power
          </span>
        </Tooltip>
      </div>
      {/* <div className="dtech-subsstation-model-peak-time-property-list-item">
        <span className="dtech-subsstation-model-property-list-item-value">
          {substationDataRecords?.circuitPower.length > 0
            ? getLargestValueofCircuitPower(
                "totalreallossespercentage"
              )?.toFixed(2) + " %"
            : 0.0}
        </span>
        <Tooltip title="Real losess(%)">
          <span className="dtech-subsstation-model-property-list-item-name">
            Real losess(%)
          </span>
        </Tooltip>
      </div> */}
      <div className="dtech-subsstation-model-peak-time-property-list-item">
        <span className="dtech-subsstation-model-property-list-item-value">
          {substationDataRecords?.circuitPower?.length > 0
            ? substationDataRecords?.circuitPower[0][
                "totalrealcustomerpowerkw"
              ]?.toFixed(2) + " kW"
            : 0.0}
        </span>
        <Tooltip title="Real customer power">
          <span className="dtech-subsstation-model-property-list-item-name">
            Real customer power
          </span>
        </Tooltip>
      </div>
      <div className="dtech-subsstation-model-peak-time-property-list-item">
        <span className="dtech-subsstation-model-property-list-item-value">
          {substationDataRecords?.circuitPower?.length > 0
            ? substationDataRecords?.circuitPower[0][
                "totalrealcommercialpowerkw"
              ]?.toFixed(2) + " kW"
            : 0.0}
        </span>
        <Tooltip title="Real commercial power">
          <span className="dtech-subsstation-model-property-list-item-name">
            Real commercial power
          </span>
        </Tooltip>
      </div>
      <div className="dtech-subsstation-model-peak-time-property-list-item">
        <span className="dtech-subsstation-model-property-list-item-value">
          {substationDataRecords?.circuitPower?.length > 0
            ? substationDataRecords?.circuitPower[0][
                "totalrealresidentialpowerkw"
              ]?.toFixed(2) + " kW"
            : 0.0}
        </span>
        <Tooltip title="Real residential power">
          <span className="dtech-subsstation-model-property-list-item-name">
            Real residential power
          </span>
        </Tooltip>
      </div>
    </>
  );

  const peakTimePropertyItems = (
    <>
      <div className="dtech-subsstation-model-peak-time-property-list-item">
        <span className="dtech-subsstation-model-peak-time-property-list-item-value">
          {substationDataRecords?.substation.length > 0
            ? getLargestValueofSubstation("reallosseskw")?.toFixed(2) + " kW"
            : 0.0}
        </span>
        <Tooltip title="Real losses">
          <span className="dtech-subsstation-model-peak-time-property-list-item-name">
            Real losses
          </span>
        </Tooltip>
      </div>
      <div className="dtech-subsstation-model-peak-time-property-list-item">
        <span className="dtech-subsstation-model-peak-time-property-list-item-value">
          {substationDataRecords?.substation.length > 0
            ? getLargestValueofSubstation("circuitpower")?.toFixed(2) + " kW"
            : 0.0}
        </span>
        <Tooltip title="Circuit power">
          <span className="dtech-subsstation-model-peak-time-property-list-item-name">
            Circuit power
          </span>
        </Tooltip>
      </div>
      {/* <div className="dtech-subsstation-model-peak-time-property-list-item">
        <span className="dtech-subsstation-model-peak-time-property-list-item-value">
          {substationDataRecords?.substation?.length > 0
            ? substationDataRecords?.substation[0][
                "reallossespercentage"
              ]?.toFixed(2) + " %"
            : 0.0}
        </span>
        <Tooltip title="Real losess(%)">
          <span className="dtech-subsstation-model-peak-time-property-list-item-name">
            Real losess(%)
          </span>
        </Tooltip>
      </div> */}
      <div className="dtech-subsstation-model-peak-time-property-list-item">
        <span className="dtech-subsstation-model-peak-time-property-list-item-value">
          {substationDataRecords?.substation.length > 0
            ? getLargestValueofSubstation("realcustomerpower")?.toFixed(2) +
              " kW"
            : 0.0}
        </span>
        <Tooltip title="Real customer power">
          <span className="dtech-subsstation-model-peak-time-property-list-item-name">
            Real customer power
          </span>
        </Tooltip>
      </div>
      <div className="dtech-subsstation-model-peak-time-property-list-item">
        <span className="dtech-subsstation-model-peak-time-property-list-item-value">
          {substationDataRecords?.substation.length > 0
            ? getLargestValueofSubstation("realcommercialpower")?.toFixed(2) +
              " kW"
            : 0.0}
        </span>
        <Tooltip title="Real commercial power">
          <span className="dtech-subsstation-model-peak-time-property-list-item-name">
            Real commercial power
          </span>
        </Tooltip>
      </div>
      <div className="dtech-subsstation-model-peak-time-property-list-item">
        <span className="dtech-subsstation-model-peak-time-property-list-item-value">
          {substationDataRecords?.substation.length > 0
            ? getLargestValueofSubstation("realresidentialpower")?.toFixed(2) +
              " kW"
            : 0.0}
        </span>
        <Tooltip title="Real residential power">
          <span className="dtech-subsstation-model-peak-time-property-list-item-name">
            Real residential power
          </span>
        </Tooltip>
      </div>
      <div className="dtech-subsstation-model-peak-time-property-list-item">
        <span className="dtech-subsstation-model-peak-time-property-list-item-value">
          {substationDataRecords?.substation.length > 0
            ? getLargestValueofSubstation("peakloadminuteofhour")?.toFixed(2) +
              " kW"
            : 0.0}
        </span>
        <Tooltip title="Peak load minute of hour">
          <span className="dtech-subsstation-model-peak-time-property-list-item-name">
            Peak load minute of hour
          </span>
        </Tooltip>
      </div>
      <div className="dtech-subsstation-model-peak-time-property-list-item">
        <span className="dtech-subsstation-model-peak-time-property-list-item-value">
          {substationDataRecords?.substation.length > 0
            ? getLargestValueofSubstation("peakloadhourofday")?.toFixed(2) +
              " kW"
            : 0.0}
        </span>
        <Tooltip title="Peak load hour of data">
          <span className="dtech-subsstation-model-peak-time-property-list-item-name">
            Peak load hour of data
          </span>
        </Tooltip>
      </div>
    </>
  );

  return (
    <>
      <div className="dtech-subsstation-section-wrapper mt-3 static-section">
        <div className="container-fluid">
          <div
            className="container-fluid"
            style={{
              height: windowHeight - 240 + "px",
              overflow: "scroll",
            }}
          >
            <div className="dtech-subsstation-header-section">
              <div>
                <span className="dtech-subsstation-heading">
                  {"Substation- "}
                </span>
                <span className="substation-name-value">
                  {substationData?.name?.replaceAll("tr(","").replaceAll(")","")}
                </span>
              </div>
              <div className="substation-header-inner-grid-container">
                <div className="substation-header-inner-grid">
                  <span
                    className="substation-header-title"
                    style={{ fontSize: 12 }}
                  >
                    Voltage levels
                  </span>
                  <span className="substation-header-title-value">
                    {(substationData?.instance.voltageLevels || [])
                      .map((level: any) => level.replace("VoltageLevel ", ""))
                      .join(", ") || "-"}
                  </span>
                </div>
                <div className="substation-header-inner-grid">
                  <span
                    className="substation-header-title"
                    style={{ fontSize: 12 }}
                  >
                    Consumers
                  </span>
                  <span className="substation-header-title-value">
                    {substationData?.instance?.customers}
                    {/* 3569 */}
                  </span>
                </div>
              </div>
            </div>
            <div className="dtech-subsstation-model-property-list-section">
              {propertyItems}
            </div>
            <div className="dtech-subsstation-model-peak-time-property-list-section">
              <h6>Total peak time data</h6>
              <div className="dtech-subsstation-model-peak-time-property-list">
                {peakTimePropertyItems}
              </div>
            </div>
            <div className="row dtech-substation-footer-chart-data-section">
              <div className="middle-chart-div">
                <ChartComponent
                  labels={labels}
                  datasets={dataset}
                  title={"Load curve"}
                  yAxisTitle={"kW Total"}
                  xAxisTitle={"Hour of the Day"}
                />

                {/* <ChartComponent
                  chartData={{datasets:dataset,label:labels}}
                  title={"Load curve"}
                /> */}
              </div>
            </div>
            <div
              className={`${
                windowWidth < 1200
                  ? "col-sm-12 dtech-map-footer-chart-data-section flex-direction-column"
                  : "col-sm-12 dtech-map-footer-chart-data-section flex-direction-row"
              }`}
            >
              <div
                className={`${
                  windowWidth < 1200 ? "col-sm-12" : "col-md-6 pe-1 col-sm-6"
                }`}
              >
                <div className="dtech-map-data-section">
                  {/* <ChartComponent
                        chartData={{
                          labels: labels,
                          datasets: datasetCircuit,
                        }}
                        title={"Voltage"}
                        yAxisTitle={"Voltage(p u)"}
                        xAxisTitle={"Hour of the Day"}
                      /> */}

                  <ScatterChartComponent
                    datasets={datasetpercentile}
                    title={"Load curve"}
                    yAxisTitle={"Voltage(p u)"}
                    xAxisTitle={"Percentile"}
                  />
                </div>
              </div>
              <div
                className={`${
                  windowWidth < 1200 ? "col-sm-12" : "col-md-6 ps-1 col-sm-6"
                }`}
              >
                <div
                  className="dtech-map-data-section"
                  style={{ height: "360px" }}
                >
                  <Alarm alarmData={alarms} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SubStation;
