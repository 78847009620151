import React, { useEffect, useMemo, useRef, useState } from "react";
import Chart, {
  ChartOptions,
  ScriptableContext,
  TimeScaleTimeOptions,
} from "chart.js/auto";
import "chartjs-adapter-moment";
import moment from "moment";
const ChartComponent = (props: any) => {
  const chartRef = useRef<any>(null);
  const chartInstanceRef = useRef<any>(null);

  const currentHourStart = moment().startOf("hour");
  const twentyFourHoursAgo = moment().subtract(24, "hours").startOf("hour");
  let [resolutioneight, setResolutionHeight] = useState<string>("100%");

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth <= 1200 && props?.title?.includes("Day Load Curve")) {
      setResolutionHeight("52vh");
    }
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      const newChartInstance = new Chart(ctx, {
        type: "line",
        data: {
          labels: props.labels,
          datasets: props.datasets,
        },
        options: {
          animation: false,
          responsive: true,
          maintainAspectRatio: false,
          events: ["mousemove", "mouseout", "click", "touchstart", "touchmove"],
          interaction: {
            mode: "nearest",
          },
          layout: {
            padding: {
              right: 0,
            },
          },
          scales: {
            x: {
              type: "time",
              time: {
                parser: "HH:mm",
                unit: "hour",
                displayFormats: {
                  hour: "HH:mm",
                },
                min: twentyFourHoursAgo.toDate(),
                max: currentHourStart.toDate(),
                unitStepSize: 1,
                stepSize: 1,
              } as any,
              title: {
                display: true,
                text: props.xAxisTitle,
                color: "#59595a",
                position: "top",
                align: "start",
                font: {
                  weight: "bold",
                },
                padding: 9,
                fullSize: true,
              } as any,
              ticks: {
                maxTicksLimit: 24,
                maxRotation: 0,
                // autoSkipPadding: 10,
                source: "auto",
                autoSkip: false,
              },
            },
            y: {
              title: {
                display: true,
                text: props.yAxisTitle,
              },
              suggestedMax: props?.suggestedMax,
            },
          },
          plugins: {
            title: {
              display: true,
              text: props?.title,
            },
            legend: {
              position: props.labelPosition ? props.labelPosition : "right",
            },
            tooltip: {
              enabled: true,
              intersect: false,
            },
          },
        },
      });

      chartInstanceRef.current = newChartInstance;
    }

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [props.datasets]);

  return (
    <span
      className="chart-span"
      style={{
        height: props?.title?.includes("Day Load Curve")
          ? resolutioneight
          : "45vh",
        display: "block",
      }}
    >
      <canvas ref={chartRef} id="myChart" />
    </span>
  );
};

export default ChartComponent;
