import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDatas, ISubsstationDatas } from "./sidebar.interface";

const initialState: IDatas = {
  Substation: {
    name: "",
    rid: "",
    mRID: "",
    type: "",
    schema: [],
    instance: {},
  },
  Bay: {
    name: "",
    rid: "",
    mRID: "",
    type: "",
    schema: [],
    instance: {},
  },

  EnergyConsumer: {
    name: "",
    rid: "",
    mRID: "",
    type: "",
    schema: [],
    instance: {},
  },
};

const sidebarSlice = createSlice({
  name: "sidebarSlice",
  initialState: initialState,
  reducers: {
    setAllDataByListItem: (state, action: PayloadAction<IDatas>) => {
      state.Substation = action?.payload?.Substation;
      state.Bay = action?.payload?.Bay;
      state.EnergyConsumer = action?.payload?.EnergyConsumer;
    },
  },
});
export const { setAllDataByListItem } = sidebarSlice.actions;
export default sidebarSlice.reducer;
